@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap');


body {
  margin: 0;
  font-family: "Noto Sans", sans-serif;
  background-color: #f4f4f4;
}

/* app-layout */
:root {
  --app-primary-color: #fff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

app-header {
  background-color: #015fac;
  color: #fff;
  --app-toolbar-font-size: 24px;
}

app-drawer app-header {
  background-color: var(--app-primary-color);
  color: #000;
}

app-drawer-layout:not([narrow]) [drawer-toggle] {
  display: none;
}

.avatar-container {
  position: relative;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin: 20px auto;
  background-color: #333;
  text-align: center;
}

.avatar-container .initials {
  position: relative;
  top: 25px; /* 25% of parent */
  font-size: 50px; /* 50% of parent */
  line-height: 50px; /* 50% of parent */
  color: #fff;
  font-family: "Noto Sans", sans-serif;
  font-weight: bold;
}

.contact-info {
  margin: 0 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  text-align: center;
}

.contact-info .name {
  font-weight: bold;
}

.contact-info .email {
  color: #999;
}

paper-icon-button {
  --paper-icon-button-ink-color: white;
}

paper-item {
  height: 54px;
}

paper-item > a {
  width: 100%;
  height: 100%;
  line-height: 54px;
  text-decoration: none;
  color: black;
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
}

nav > div {
  text-align: left;
}

paper-fab {
  position: fixed;
  right: 24px;
  bottom: 24px;

  --paper-fab-background: #ef5458;
  --paper-fab-keyboard-focus-background: #df4448;
}


date-picker,
time-picker,
datetime-picker {
  --input-background: white;
  --input-color: #222;
  --input-picker-background: white;
  --input-picker-color: #222;
  --input-focus-background: white;
  --input-focus-color: #222;
  --inner-input-focus-background: white;
  --inner-input-focus-border-color: transparent;
  --inner-input-focus-color: #222;
}
